import React from "react";
// Styles
import "./Footer.scss";

const Footer = () => (
  <footer className="footer">
    <p>Prisca Aure © {new Date().getFullYear()} | Tous droits réservés</p>
  </footer>
);

export default Footer;
