import React, { Fragment, Component } from 'react';
import { Helmet } from 'react-helmet';
import posed from 'react-pose';
// Prismic
import NotFound from '../../NotFound'
import { RichText } from 'prismic-reactjs';
// Components
import Nav from '../../components/Nav/Nav'
import Footer from '../../components/Footer/Footer'
import ProgressBar from '../../utils/ProgressBar/ProgressBar';
// Styles
import './Contact.scss'

const Paragraph = posed.div({
    open: { 
        y: 0, 
        opacity: 1,
        transition : {
            duration: 350,
            type: 'spring',
            stiffness: 300
        }
    },
    closed: { y: -50, opacity: 0 }
});

const Container = posed.div({
    open: { 
        x: '0%',
        delayChildren: 700,
        staggerChildren: 300,
        transition : {
            duration: 400,
            ease: 'easeInOut'
        }
    },
    closed: { 
        x: '-100%',
        delay: 200,
        transition : {
            ease: 'easeOut'
        }
    }
});

export default class Contact extends Component {
	constructor(props) {
		super(props);
		ProgressBar.init()

		this.state = {
			doc: null,
			notFound: false,
			isOpen: false,
            open: false
		}
		if (props.prismicCtx) {
			this.fetchPage(props);
		}
	}

	componentDidUpdate(prevProps) {
	    this.props.prismicCtx.toolbar();
	    if (!prevProps.prismicCtx) {
	  	    this.fetchPage(this.props);
	    }
	}

	fetchPage(props) {
        // We are using the function to get single type document
        return props.prismicCtx.api.getSingle('contact').then(doc => {
            if (doc) {
            // We put the retrieved content in the state as a doc variable
            this.setState({ doc });
            } else {
                // We changed the state to display error not found if no matched doc
                this.setState({ notFound: !doc });
            }
        });
	}

	contentSection() {
		const { isOpen } = this.state;
		return (
            <Container className="flex-container" pose={isOpen ? 'open' : 'closed'}>
                <Paragraph className="title">{RichText.render(this.state.doc.data.content, this.props.prismicCtx.linkResolver)}</Paragraph>
				<Footer />
			</Container>
		);
	}

	componentDidMount() {
        setTimeout(this.toggle, 1000)
	}
	
	toggle = () => this.setState({ isOpen: !this.state.isOpen })

	render() {
		if (!this.state.doc) { 
			ProgressBar.start();
            return <div />
		} else if (this.state.notFound){
			return <NotFound />
		}
		ProgressBar.stop();

		return (
			<Fragment>
				<Helmet>
					<title>{RichText.asText(this.state.doc.data.title)}</title>
				</Helmet>
				<Nav />
				{this.contentSection()}
			</Fragment>
		);
	}
}