import React from "react";
import { Link } from "react-router-dom";
// Assets
import Logo from "../../assets/img/logo.svg";
// Styles
import "./Nav.scss";

const links = [{ href: "/contact", label: "Contact" }].map((link) => {
  link.key = `nav-link-${link.href}-${link.label}`;
  return link;
});

const Nav = () => (
  <nav>
    <ul>
      <li>
        <Link to="/">
          <img src={Logo} alt="Logo" width="62" height="60" />
        </Link>
      </li>
      <ul>
        {links.map(({ key, href, label }) => (
          <li key={key}>
            <Link to={href}>{label}</Link>
          </li>
        ))}
      </ul>
    </ul>
  </nav>
);

export default Nav;
