import './ProgressBar.scss'
import NProgress from 'nprogress'

const ProgressBar = {
    init: function () {
        let templateDiv = '<div class="bar" role="bar"><div class="peg"></div></div>'
        NProgress.configure({
            showSpinner: false,
            template: templateDiv
        });
    },
    start: function () {
        NProgress.start();
    },
    stop: function () {
        NProgress.done();
    }
};
export default ProgressBar