import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
// Pages
import Home from './pages/Home/Home'
import Post from './pages/Post/Post'
import Contact from './pages/Contact/Contact'
// Prismic
import Preview from './Preview';
import Help from './Help';
import NotFound from './NotFound';
// Styles
import './App.scss'

const App = (props) => (
  <Router>
    <Switch>
      <Redirect exact from="/project/" to="/" />
      <Route exact path="/" render={routeProps => <Home {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route exact path="/help" component={Help} />
      <Route exact path="/preview" render={routeProps => <Preview {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route exact path="/project/:uid" render={routeProps => <Post {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route exact path="/contact" render={routeProps => <Contact {...routeProps} prismicCtx={props.prismicCtx} />} />
      <Route component={NotFound} />
    </Switch>
  </Router>
);

export default App;