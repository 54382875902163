import React from 'react';
import { Helmet } from 'react-helmet';
import posed from 'react-pose'
// Prismic
import NotFound from '../../NotFound';
import { Link, RichText } from 'prismic-reactjs';
// Components
import ProgressBar from '../../utils/ProgressBar/ProgressBar';
// Assets
import iconClose from '../../assets/img/icon-close.svg'
// Styles
import './Post.scss'

const Close = posed.img({
  pressable: true,
  init: { scale: 1 },
  press: { scale: 0.8 },
  open: { y: 0, opacity: 1},
  closed: { y: 20, opacity: 0 }
})

const Image = posed.div({
  open: { y: 0, opacity: 1},
  closed: { y: 20, opacity: 0 }
})

const Content = posed.div({
  open: { 
      y: 0, 
      opacity: 1,
      transition : {
          duration: 200,
          type: 'spring',
          stiffness: 100
      }
  },
  closed: { y: -50, opacity: 0 }
});

const Page = posed.div({
  open: { 
      x: '0%',
      delayChildren: 700,
      staggerChildren: 300,
      transition : {
          duration: 400,
          ease: 'easeInOut'
      }
  },
  closed: { 
      x: '-100%',
      delay: 200,
      transition : {
          ease: 'easeOut'
      }
  }
});

// Declare your component
export default class Post extends React.Component {
  constructor(props){
    super(props);
    ProgressBar.init()
    
    this.state = {
      doc: null,
      notFound: false,
      isOpen: false,
      open: false
    }
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentDidUpdate(prevProps) {
    this.props.prismicCtx.toolbar();
    // We fetch the page only after props have changed and PrismicCtx is no longer null 
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {
    // We are using the function to get a document by its uid
    return props.prismicCtx.api.getByUID('blog_post', props.match.params.uid, {}, (err, doc) => {
      if (doc) {
        // We put the retrieved content in the state as a doc variable
        this.setState({ doc });
      } else {
        // We changed the state to display error not found if no matched doc
        this.setState({ notFound: !doc });
      }
    });
  }

  renderLink() {
    var target = {};
    if(this.state.doc.data.link.target) {

      target = { 
        target: this.state.doc.data.link.target,
        rel: "noopener"
      };

      return <a className="card-link" href={Link.url(this.state.doc.data.link)} {...target}><p>Voir le site web</p></a> ;
    } else {
      return null
    }
  }

  componentDidMount() {
    setTimeout(this.toggle, 1000)
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })

  render() {
    if (!this.state.doc) { 
			ProgressBar.start();
      return <div />
		} else if (this.state.notFound){
			return <NotFound />
		}
    ProgressBar.stop();

    let titled = this.state.doc.data.title.length !== 0;
    const { isOpen } = this.state;
      return (
        <Page className="post-page" pose={isOpen ? 'open' : 'closed'}>
          <Helmet>
                  <title>{titled ? RichText.asText(this.state.doc.data.title) : 'Untitled'}</title>
          </Helmet>
          <section className="post-section">
            <a href="/"><Close className="close" src={iconClose} alt="close"/></a>
              <Image className="img-header">
                <h1>{titled ? RichText.asText(this.state.doc.data.title) : 'Untitled'}</h1>
                <div className="img-wrapper" style={{ background: `url(${this.state.doc.data.post_image.url}) center center/cover no-repeat` }}/> 
              </Image>
              <Content className="post-content">
                { this.renderLink() }
                {RichText.render(this.state.doc.data.content, this.props.prismicCtx.linkResolver)}
              </Content>
          </section>
        </Page>
    );
  }
}