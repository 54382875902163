import React, { Fragment, Component } from "react";
import posed from "react-pose";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
// Prismic
import NotFound from "../../NotFound";
import { RichText } from "prismic-reactjs";
import Prismic from "prismic-javascript";
// Components
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";
import ProgressBar from "../../utils/ProgressBar/ProgressBar";
// Assets
import iconLink from "../../assets/img/icon-link.svg";
import iconPicture from "../../assets/img/icon-picture.svg";
// Styles
import "./Home.scss";

const H1 = posed.h1({
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 200,
      type: "spring",
      stiffness: 200,
    },
  },
  closed: { y: -50, opacity: 0 },
});

const Span = posed.span({
  open: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 350,
      type: "spring",
      stiffness: 300,
    },
  },
  closed: { y: -50, opacity: 0 },
});

const Hero = posed.div({
  open: {
    y: "0%",
    delayChildren: 100,
    staggerChildren: 50,
  },
  closed: { y: "-100%", delay: 500 },
});

const Section = posed.section({
  open: {
    x: "0%",
    delayChildren: 700,
    staggerChildren: 500,
    transition: {
      duration: 400,
      ease: "easeInOut",
    },
  },
  closed: { x: "-100%" },
});

const Item = posed.div({
  open: { y: 0, opacity: 1 },
  closed: { y: 20, opacity: 0 },
});

export default class Home extends Component {
  constructor(props) {
    super(props);
    ProgressBar.init();

    this.state = {
      doc: null,
      notFound: false,
      posts: [],
      isOpen: false,
      open: false,
    };
    if (props.prismicCtx) {
      this.fetchPage(props);
    }
  }

  componentDidUpdate(prevProps) {
    this.props.prismicCtx.toolbar();
    if (!prevProps.prismicCtx) {
      this.fetchPage(this.props);
    }
  }

  fetchPage(props) {
    // We are using the function to get single type document
    return props.prismicCtx.api.getSingle("blog_home").then((doc) => {
      if (doc) {
        // We put the retrieved content in the state as a doc variable
        this.setState({ doc });
        props.prismicCtx.api
          .query(
            // Get the blog posts in descending order
            Prismic.Predicates.at("document.type", "blog_post"),
            { orderings: "[document.first_publication_date desc]" }
          )
          .then((res) => {
            this.setState({ posts: res.results });
          });
      } else {
        // We changed the state to display error not found if no matched doc
        this.setState({ notFound: !doc });
      }
    });
  }

  blogPostsSection() {
    const { isOpen } = this.state;
    return (
      <Section className="section" pose={isOpen ? "open" : "closed"}>
        <div className="row">
          {/* Working from the array of all blog posts, we process each one */}
          {this.state.posts.map((post) => {
            /* Default title when post has no title set */
            const defaultTitle = [<h1 key="title">Untitled</h1>];
            const icon =
              post.data.type_card === "link" ? (
                <img className="iconType" src={iconLink} alt="link" />
              ) : (
                <img className="iconType" src={iconPicture} alt="icon" />
              );
            return (
              <Item key={post.id}>
                <Link
                  to={this.props.prismicCtx.linkResolver(post)}
                  className="card"
                >
                  <h3>
                    {post.data.title.length !== 0
                      ? RichText.asText(post.data.title)
                      : defaultTitle}
                  </h3>
                  {icon}
                  <span className="cardNumber">{post.data.card_number}</span>
                  <div className="cardContent">
                    <div
                      className="cardImgWrapper"
                      style={{
                        transform: `rotate(${
                          post.data.card_degre + "deg"
                        }) scale(1)`,
                        background: `url(${post.data.post_image.url}) center center/cover no-repeat`,
                      }}
                    />
                  </div>
                </Link>
              </Item>
            );
          })}
        </div>
        <Footer />
      </Section>
    );
  }

  blogHomeHead() {
    const { isOpen } = this.state;
    return (
      <Fragment>
        <Hero className="hero" pose={isOpen ? "open" : "closed"}>
          <H1 className="title">
            {RichText.asText(this.state.doc.data.headtitle)}
          </H1>
          <Span>{RichText.asText(this.state.doc.data.description)}</Span>
        </Hero>
      </Fragment>
    );
  }

  componentDidMount() {
    setTimeout(this.toggle, 1000);
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    if (!this.state.doc) {
      ProgressBar.start();
      return <div />;
    } else if (this.state.notFound) {
      return <NotFound />;
    }

    ProgressBar.stop();

    return (
      <Fragment>
        <Helmet>
          <link rel="canonical" href="https://www.priscaure.fr" />
          <meta
            name="description"
            content="Prisca Aure est une UI Designer, Graphiste Webdesigner et Intégratrice ReactJS freelance, basée à Paris."
          />
          <meta
            property="og:title"
            content="Prisca Aure | Graphiste Webdesigner"
          />
          <meta
            property="og:site_name"
            content="Prisca Aure | Graphiste Webdesigner"
          />
          <meta property="og:url" content="https://priscaure.fr" />
          <meta
            property="og:description"
            content="Prisca Aure est une UI Designer, Graphiste Webdesigner et Intégratrice ReactJS freelance, basée à Paris."
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://zupimages.net/up/19/21/3lkm.jpg"
          />
        </Helmet>
        <Nav />
        {this.blogHomeHead()}
        {this.blogPostsSection()}
      </Fragment>
    );
  }
}
